import { ApiErrorUtil } from "../Util/ApiErrorUtil";
import api from "./api/api";

const getPresenca = async (params) => {
    try {
        const queryParams = new URLSearchParams();

        Object.keys(params).forEach(key => {
          if (params[key] !== null) {
            queryParams.append(key, params[key]);
          }
        });

        const { data } = await api.get("/Relatorio/Presenca?"+ queryParams);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}


export const relatorioService = {
    getPresenca
}