import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import RelatorioPresenca from '../../../components/Relatorios/RelatorioPresenca';
import RelatorioFinanceiro from '../../../components/Relatorios/RelatorioFinanceiro';

const relatorios = ["Presença", "Financeiro", /*"Candidatos"*/];

const PageRelatorios = () => {
    const [relatorioSelectedIndex, setRelatorioSelectedIndex] = useState(-1);

    const handleSelectChange = (event) => {
        setRelatorioSelectedIndex(event?.target?.value ?? -1);
    };

    return (
        <>
            <Row className='d-flex justify-content-center'>
                <div className='my-2'></div>
                <Col md={12}>
                    <h2><FontAwesomeIcon icon={faFileInvoice} /> Relatórios</h2>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={relatorioSelectedIndex}
                    >
                        <option value={-1}>Selecionar relatório</option>
                        {relatorios.map((rel, index) => (
                            <option key={index} value={index}>{rel}</option>
                        ))}
                    </Form.Select>
                </Col>

                {relatorioSelectedIndex == -1 && (
                    <Col md={8} style={{ height: '60vh' }}>
                        <h5 className='text-muted text-center py-5'>Nenhum relatório selecionado</h5>
                    </Col>
                )}
            </Row>

            <Row className='d-flex justify-content-center mt-3'>
                <Col md={12}>
                    {relatorioSelectedIndex == 0 && (
                        <RelatorioPresenca />
                    )}
                    {/* {relatorioSelectedIndex == 1 && (
                        <RelatorioCandidato />
                    )} */}
                    {relatorioSelectedIndex == 1 && (
                        <RelatorioFinanceiro />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default PageRelatorios;
