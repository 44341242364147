const reduzirTamanho = (imageFile, maxSizeInBytes = (1536 * 1024)) => {
    return new Promise((resolve, reject) => {
        // Check if the image size exceeds the maximum size
        if (imageFile.size > maxSizeInBytes) {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const width = image.width;
                    const height = image.height;
                    const aspectRatio = width / height;

                    // Calculate new dimensions to maintain aspect ratio
                    let newWidth = width;
                    let newHeight = height;
                    if (width > height) {
                        newWidth = Math.min(Math.sqrt(maxSizeInBytes / aspectRatio), width);
                        newHeight = newWidth / aspectRatio;
                    } else {
                        newHeight = Math.min(Math.sqrt(maxSizeInBytes * aspectRatio), height);
                        newWidth = newHeight * aspectRatio;
                    }

                    // Set canvas dimensions
                    canvas.width = newWidth;
                    canvas.height = newHeight;

                    // Draw image on canvas
                    ctx.drawImage(image, 0, 0, newWidth, newHeight);

                    // Get Base64 string from canvas
                    const base64String = canvas.toDataURL(imageFile.type);

                    resolve(base64String);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(imageFile);
        } else {
            // Image size is within the limit, return the original file as Base64
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.readAsDataURL(imageFile);
        }
    });
};

export const imageUtil = {
    reduzirTamanho
};
