import React, { useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { formatarData } from '../../Util/DataUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileSignature, faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../Context/UserContext';
import { useContext } from 'react';
import ModalRegistroPresenca from '../Modal/RegistroPresenca/ModalRegistroPresenca';
import ModalListaRegistroPresenca from '../Modal/RegistroPresenca/ModalListaRegistroPresenca';

function TableCandidatos({ candidatos, onDeleteClick, onEditClick }) {
  const { user } = useContext(UserContext);
  const [showModalRegistro, setShowModalRegistro] = useState(false);
  const [showModalLista, setShowModalLista] = useState(false);
  const [candidatoSelecionado, setCandidatoSelecionado] = useState(null);

  const handleLancarPresencaClick = (candidato) => {
    setCandidatoSelecionado(candidato);
    setShowModalRegistro(true);
  };

  const handleVerPresencaClick = (candidato) => {
    setCandidatoSelecionado(candidato);
    setShowModalLista(true);
  };

  const handleCloseRegistroModal = () => {
    setShowModalRegistro(false);
  };

  const handleCloseListaModal = () => {
    setShowModalLista(false);
  };

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Nascimento</th>
            <th>Comunidade crisma</th>
            <th>Mãe</th>
            <th>Data Cadastro</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {candidatos?.map((candidato) => (
            <tr key={candidato.id}>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{candidato.id}</td>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{candidato.nome}</td>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{formatarData(candidato.dataNascimento)}</td>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{candidato.comunidade?.nome}</td>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{candidato.nomeMae}</td>
              <td onClick={() => user.role === 'adm' ? onEditClick(candidato) : null}>{formatarData(candidato.createdAt, true)}</td>
              <td>
                {user.role === 'adm' && (
                  <Button variant='outline-danger' onClick={() => onDeleteClick(candidato)}><FontAwesomeIcon icon={faTrash} /></Button>
                )}
                {user.role === 'catequista' && (
                  <>
                    <Button variant='outline-info' className='me-1' onClick={() => handleVerPresencaClick(candidato)}><FontAwesomeIcon icon={faEye}/> </Button>
                    <Button variant='outline-primary' onClick={() => handleLancarPresencaClick(candidato)}><FontAwesomeIcon icon={faFileSignature} /></Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModalRegistroPresenca
        show={showModalRegistro}
        onHide={handleCloseRegistroModal}
        candidatoId={candidatoSelecionado ? candidatoSelecionado.id : null}
      />
      <ModalListaRegistroPresenca
        show={showModalLista}
        onHide={handleCloseListaModal}
        candidatoId={candidatoSelecionado ? candidatoSelecionado.id : null}
      />
    </>
  );
}

export default TableCandidatos;
