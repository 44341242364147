import { toast } from "react-toastify";

const showError = (error) => {
    // const user = localStorage.getItem("user");
    // if (!user) return;


    let dataError = error?.response?.data ?? error?.message;

    if (!dataError) {
        toast.error('Erro desconhecido');
        return;
    }

    if (Array.isArray(dataError)) {
        dataError.forEach(msg => {
            toast.error(msg)
        });
        return;
    }

    toast.error(dataError);
}

export const ApiErrorUtil = {
    showError
}